//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
import { i18n } from '@/i18n';
import Roles from '@/security/roles';
import IamViewToolbar from '@/modules/iam/components/iam-view-toolbar.vue';

const { fields } = UserModel;

export default {
  name: 'app-iam-view-page',

  props: ['id'],

  components: {
    [IamViewToolbar.name]: IamViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'iam/view/record',
      loading: 'iam/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'iam/view/doFind',
    }),

    presenter(record, fieldName) {
      return UserModel.presenter(record, fieldName);
    },

    roleDescriptionOf(roleId) {
      return Roles.descriptionOf(roleId);
    },

    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },

    i18n(code) {
      return i18n(code);
    },
  },
};
